<template>
  <section
    :id="containerId"
    ref="container"
    class="relative flex justify-center h-full overflow-hidden"
    @mousemove="onMouseMove"
    @mouseup="onMouseUp"
    @mouseleave="onMouseLeave"
  >
    <div class="absolute top-0 w-full h-full viewer-ui">
      <HelpOverlay
        v-if="showHelpOverlay"
        @close-overlay="showHelpOverlay = false"
      />

      <ViewModifier
        v-if="showViewModifier"
        :highlight="highlight"
        :highlighted-group="highlightedGroup"
        :viewer-highlighted="viewerHighlighted"
        @reset="resetViewModifications"
        @clear-highlight="clearHighlight"
      />

      <!-- <DebugMenu
    v-if="gui && showDebugOverlay"
    v-bind:gui="gui"
    v-on:close="showDebugOverlay = false"/> -->

      <ViewControls
          v-if="showTopControls && !showDebugOverlay"
          v-bind:bookmarks="bookmarks"
          v-bind:active-bookmark="activeBookmark"
          v-bind:last-active-bookmark="lastActiveBookmark"
          v-on:view-bookmark="viewBookmark"
          v-on:view-next-bookmark="viewNextBookmark"
          v-on:view-prev-bookmark="viewPrevBookmark"
          v-on:toggle-pick-mode="togglePickMode"
        />


      <SettingsMenu
        v-if="showSettingsMenu"
        :render-mode="renderMode"
        :render-quality="renderQuality"
        :render-post-processing-enabled="renderPostProcessingEnabled"
        :render-post-processing-available="renderPostProcessingAvailable"
        @close="closeSettingsMenu"
        @change-render-mode="changeRenderMode"
        @change-render-quality="changeRenderQuality"
        @change-render-postprocessing="changeRenderPostProcessing"
      />

      <MessagesOverlay
        :offset-bottom="showPlaybackControls || showLeftControls"
        :loading-message="loadingMessage"
        :messages="messages"
      />

      <PlaybackMenu
        v-if="showPlaybackMenu"
        :animations="animations"
        :active-animation="activeAnimation"
        @view-playback-content="viewPlaybackContent"
        @close="closePlaybackMenu"
      />

      <div
        class="absolute bottom-0 z-40 w-full overflow-hidden select-none playbar"
        v-show="!supressControls"
      >
        <div
          class="flex items-center justify-between w-full text-xs text-white bg-transparent playbar-controls"
        >
          <PlaybackControls
            v-if="showPlaybackControls"
            :show-playback-menu="showPlaybackMenu"
            :active-animation="activeAnimation"
            :animation-is-playing="animationIsPlaying"
            :animation-time="animationTime"
            @show-playback="togglePlaybackMenu"
            @toggle-play-animation="animationTogglePlayState"
            @scrub-animation="scrubAnimation"
          />
          <LeftControls
            v-else-if="showLeftControls && !showDebugOverlay"
            :player-state="playerState"
            :current-timecode="currentTimecode"
            :duration-formatted="durationFormatted"
            @toggle-play="togglePlayState"
          />
          <div v-else class="flex left-side" />

          <ProgressBar
            v-if="showProgressBar && !showDebugOverlay"
            :duration="duration"
            :progress-pc="progressPc"
            @jump-to-time="jumpToTime"
            @mouse-down="onMouseDown"
          />

          <RightControls
            v-if="showRightControls"
            :is-fullscreen="isFullscreen"
            :show-settings-menu="showSettingsMenu"
            :show-help="showHelpOverlay"
            @show-help="showHelpOverlay = true"
            @show-settings="toggleSettingsMenu"
            @toggle-fullscreen="toggleFullscreen"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ResizeObserver from 'resize-observer-polyfill'

const HelpOverlay = () => import('./controls/HelpOverlay')
const DebugMenu = () => import('./controls/DebugMenu')
const ViewModifier = () => import('./controls/ViewModifier')
const SettingsMenu = () => import('./controls/SettingsMenu')
const ViewControls = () => import('./controls/ViewControls')
const RightControls = () => import('./controls/RightControls')
const LeftControls = () => import('./controls/LeftControls')
const PlaybackControls = () => import('./controls/PlaybackControls')
const PlaybackMenu = () => import('./controls/PlaybackMenu')
const ProgressBar = () => import('./controls/ProgressBar')
const MessagesOverlay = () => import('./controls/MessagesOverlay')

export default {
  name: 'PrevizViewer',

  components: {
    DebugMenu,
    HelpOverlay,
    ViewModifier,
    SettingsMenu,
    ViewControls,
    RightControls,
    LeftControls,
    PlaybackControls,
    PlaybackMenu,
    ProgressBar,
    MessagesOverlay
  },

  props: {
    id: {
      type: String,
      required: true
    },

    mode: {
      type: String,
      default: 'scene'
    },

    supressControls: {
      type: Boolean,
      default: false
    },

    containerId: {
      type: String,
      default: 'appContainer'
    }
  },

  data() {
    return {
      showDebugOverlay: false,
      showHelpOverlay: false,
      showSettingsMenu: false,
      showPlaybackMenu: false,

      sequenceHelper: null,
      viewerReady: false,
      isFullscreen: false,

      gui: null,
      core: null,
      activecallback: null
    }
  },

  computed: {
    timeline() {
      if (this.hasTimeline) return this.core.sequence.timeline
      return null
    },

    hasTimeline() {
      if (!this.core) return false
      if (!this.core.sequence) return false
      if (!this.core.sequence.timeline) return false
      return true
    },

    timelineId() {
      if (!this.core) return null
      if (!this.core.sequence) return null
      return this.core.sequence.id
    },

    hasSequence() {
      if (!this.core) return false
      if (!this.core.sequence) return false
      return true
    },

    messages() {
      if (this.gui === null) return []
      return this.gui.recentMessages
    },

    loadingMessage() {
      if (this.gui === null || this.gui.isLoading === false) return null
      return {
        message: 'Loading',
        pc: this.gui.loadingPc
      }
    },

    showAnimationsControls() {
      if (this.gui === null) return false
      return this.gui.animationsAvailable
    },

    activeAnimation() {
      if (this.gui === null) return null
      return this.gui.animationsActive
    },

    animations() {
      if (this.gui === null) return []
      return this.gui.animations
    },

    animationPlaybackMode() {
      if (this.gui === null) return null
      return this.gui.animationPlaybackMode
    },

    animationIsPlaying() {
      if (this.gui === null) return null
      return this.gui.animationIsPlaying
    },

    animationTime() {
      if (this.gui === null) return null
      return this.gui.animationTime
    },

    renderPostProcessingAvailable() {
      if (this.gui === null) return false
      return this.gui.renderPostProcessingAvailable
    },

    renderPostProcessingEnabled() {
      if (this.gui === null) return false
      return this.gui.renderPostProcessingEnabled
    },

    pickMode() {
      if (this.gui === null) return false
      return this.gui.pickMode
    },

    showPickMode() {
      return true // Disable when not editing // @todo
    },

    bookmarks() {
      if (this.gui === null) return []
      return this.gui.bookmarks
    },

    activeBookmark() {
      if (this.gui === null) return null
      return this.gui.activeBookmark
    },

    lastActiveBookmark() {
      if (this.gui === null) return null
      return this.gui.lastActiveBookmark
    },

    highlightedGroup() {
      if (this.gui === null || this.gui.meshHelper === null) return null
      return this.gui.meshHelper.highlightedGroup
    },

    showViewModifier() {
      return this.highlighedCount > 0
    },

    highlighedCount() {
      if (this.viewerHighlighted === null) return 0
      return this.viewerHighlighted.length
    },

    viewerHighlighted() {
      if (this.gui === null || this.gui.meshHelper === null) return null
      return this.gui.meshHelper.highlighted
    },

    hasPlaybackContent() {
      return this.hasSequence || this.hasAnimations
    },

    hasAnimations() {
      return this.animations.length > 0
    },

    showPlaybackControls() {
      if (this.supressControls && !this.showDebugOverlay && !this.isFullscreen)
        return false
      return this.hasAnimations
    },

    showLeftControls() {
      if (!this.viewerReady) return false
      if (this.supressControls && !this.isFullscreen) return false

      return this.hasPlaybackContent
    },

    showProgressBar() {
      if (!this.viewerReady) return false
      if (this.supressControls && !this.showDebugOverlay && !this.isFullscreen)
        return false

      return this.hasSequence
    },

    showRightControls() {
      return this.viewerReady && !this.showDebugOverlay
    },

    showTopControls() {
      return this.viewerReady
    },

    ready() {
      return this.timeline !== null
    },

    playerState() {
      if (this.isBlocked) return 'blocked'
      if (this.isPlaying) return 'playing'
      return 'paused'
    },

    isPlaying() {
      if (this.ready) {
        return this.timeline !== null && this.timeline.isPlaying
      }
      return false
    },

    isBlocked() {
      if (this.ready) {
        return this.timeline !== null && this.timeline.isBlocked
      }
      return false
    },

    currentTime() {
      if (this.timeline === null) return 0
      return this.timeline.currentTime
    },

    progressPc() {
      if (this.timeline === null) return 0
      return this.timeline.progressPc
    },

    currentTimecode() {
      if (this.timeline === null) return 0
      return this.timeline.currentTimecode
    },

    duration() {
      if (this.timeline === null) return 0
      return this.timeline.duration
    },

    durationFormatted() {
      if (this.timeline === null) return '00:00'
      return this.timeline.durationFormatted
    },

    currentSeconds() {
      if (this.timeline === null) return 0
      return this.timeline.currentSeconds
    },

    playbackRate() {
      if (this.timeline === null) return 1
      return Number.parseFloat(this.timeline.playbackRate)
    },

    canIncreasePlaybackRate() {
      return this.playbackRate < 10
    },

    canDecreasePlaybackRate() {
      return this.playbackRate > 0.2
    },

    layers() {
      if (this.timeline !== null) return this.timeline.layers
      return null
    },

    activeLayers() {
      if (this.timeline !== null) return this.timeline.active
      return null
    },

    resourceItems() {
      return this.seqResources.items
    },

    renderMode() {
      return this.gui.renderMode
    },

    renderQuality() {
      return this.gui.renderQuality
    }
  },

  watch: {
    timelineId() {
      this.handleViewerAttached(this.core)
    }
  },

  mounted() {
    this.attachFullscreenListener()
    this.attachKeyboardListeners()
    this.attachGlobalListeners()
    this.attachViewerReadyListener()
    this.attachResizeObserver()
  },

  beforeDestroy() {
    this.dettachKeyboardListeners()
    this.dettachFullscreenListener()
  },

  methods: {
    closeSettingsMenu() {
      this.showSettingsMenu = false
    },

    toggleSettingsMenu() {
      this.showSettingsMenu = !this.showSettingsMenu
      if (this.showSettingsMenu) this.closePlaybackMenu()
    },

    closePlaybackMenu() {
      this.showPlaybackMenu = false
    },

    togglePlaybackMenu() {
      this.showPlaybackMenu = !this.showPlaybackMenu
      if (this.showPlaybackMenu) this.closeSettingsMenu()
    },

    // turnEditorModeOn() {
    //   this.core.runsInEditorMode = true
    // },

    attachFullscreenListener() {
      document.addEventListener(
        'fullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.addEventListener(
        'webkitfullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.addEventListener(
        'mozfullscreenchange',
        this.onFullscreenChange,
        false
      )
    },

    dettachFullscreenListener() {
      document.removeEventListener(
        'fullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.removeEventListener(
        'webkitfullscreenchange',
        this.onFullscreenChange,
        false
      )
      document.removeEventListener(
        'mozfullscreenchange',
        this.onFullscreenChange,
        false
      )
    },

    onFullscreenChange() {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
    },

    changeRenderPostProcessing(state) {
      if (state === true) {
        this.gui.renderPostProcessingEnable()
      } else {
        this.gui.renderPostProcessingDisable()
      }
    },

    togglePickMode() {
      this.gui.pickMode = !this.pickMode
    },

    attachKeyboardListeners() {
      document.addEventListener('keyup', this.handleKeyboardEvents)
    },

    dettachKeyboardListeners() {
      document.removeEventListener('keyup', this.handleKeyboardEvents)
    },

    attachGlobalListeners() {
      this.$bus.$on('viewer:toggle-fullscreen', this.toggleFullscreen)
      this.$bus.$on('viewer:view-next-bookmark', this.viewNextBookmark)
      this.$bus.$on('viewer:view-prev-bookmark', this.viewPrevBookmark)
      this.$bus.$on('viewer:view-default-bookmark', this.viewDefaultBookmark)
    },

    handleKeyboardEvents(event) {
      if (this.gui === null) return

      let activeElementType = document.activeElement.nodeName

      // We only listen for input if the activeElement is NOT an input, or text area
      if (
        !(activeElementType === 'INPUT' || activeElementType === 'TEXTAREA')
      ) {
        switch (event.code) {
          // conflicts with the transform control in 3D scene for rotation and scale

          case 'KeyQ':
            this.cycleRenderQuality()
            break
          case 'KeyB':
            this.viewNextBookmark()
            break
          case 'KeyF':
            this.toggleFullscreen()
            break
          case 'KeyD':
            this.toggleDebug()
            break
          case 'Space':
            this.togglePlayState()
            break
          case 'KeyK':
            this.togglePlayState()
            break
          case 'KeyJ':
            this.playbackJumpLeft()
            break
          case 'KeyL':
            this.playbackJumpRight()
            break
          case 'Period':
            this.playbackStepRight()
            break
          case 'Comma':
            this.playbackStepLeft()
            break
        }
      }
    },

    viewDefaultBookmark() {
      this.gui.activateDefaultBookmark()
    },

    cycleRenderMode() {
      this.gui.cycleRenderMode()
    },

    cycleRenderQuality() {
      this.gui.cycleRenderQuality()
    },

    changeRenderMode(mode) {
      this.gui.changeRenderMode(mode)
    },

    changeRenderQuality(quality) {
      this.gui.changeRenderQuality(quality)
    },

    toggleDebug() {
      this.showDebugOverlay = !this.showDebugOverlay
    },

    playbackJumpLeft() {
      this.timeline.jumpTimeLeft()
    },
    playbackJumpRight() {
      this.timeline.jumpTimeRight()
    },
    playbackStepLeft() {
      this.timeline.stepTimeLeft()
    },
    playbackStepRight() {
      this.timeline.stepTimeRight()
    },

    viewBookmark(bookmark) {
      this.gui.activateBookmark(bookmark)
    },

    viewNextBookmark() {
      this.gui.activateNextBookmark()
    },

    viewPrevBookmark() {
      this.gui.activatePrevBookmark()
    },

    onMouseMove(event) {
      if (this.activecallback !== null) {
        this.activecallback(event.movementX)
      }
    },

    onMouseDown(callback) {
      this.activecallback = callback
    },

    onMouseUp() {
      this.activecallback = null
    },

    onMouseLeave() {
      this.activecallback = null
    },

    toggleFullscreen() {
      this.requestToggleFullscreen()
    },

    requestToggleFullscreen() {
      let element = this.$refs.container
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (element.requestFullScreen) {
          element.requestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen(element.ALLOW_KEYBOARD_INPUT)
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },

    attachResizeObserver() {
      this.observer = new ResizeObserver((entries, observer) => {
        var event = new CustomEvent('resize')
        window.dispatchEvent(event)
      })

      this.observer.observe(this.$refs.container)
    },

    animationTogglePlayState(name) {
      this.gui.togglePlayAnimation(name)
    },

    viewPlaybackContent(name) {
      this.animationTogglePlayState(name)
      this.closePlaybackMenu()
    },

    scrubAnimation(name, time) {
      this.gui.scrubAnimation(name, time)
    },

    togglePlayState() {
      this.timeline.togglePlay()
    },

    jumpToStart() {
      this.timeline.jumpToStart()
    },

    increasePlaybackRate() {
      this.timeline.increasePlaybackRate()
    },

    decreasePlaybackRate() {
      this.timeline.decreasePlaybackRate()
    },

    jumpToTime(value) {
      this.timeline.jumpToTime(value)
    },

    attachViewerReadyListener() {
      window.addEventListener(
        'previz-viewer-attached',
        function (event) {
          this.handleViewerAttached(event.detail.core)
          // this.core.turnEditorModeOn()
        }.bind(this),
        false
      )
    },

    handleViewerAttached(core) {
      if (core !== undefined) {
        this.core = core
        this.gui = this.core.gui
        this.viewerReady = true
      }
    },

    resetViewModifications() {
      this.gui.meshHelper.resetAll()
    },

    clearHighlight(highlight) {
      this.gui.meshHelper.unhighlightMesh(highlight)
    },

    toggleMeshTextures() {
      this.sendViewerEvent('view.toggleTextures')
    },

    toggleWireframes() {
      this.sendViewerEvent('view.toggleWireframes')
    },

    sendViewerEvent(key) {
      this.$bus.$emit('viewer:action', {
        key: key,
        value: ''
      })
    }
  }
}
</script>
